<template>
  <!-- 安全中心 -->
  <div class="safe-center">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="修改密码" name="first">
        <div class="bill-form">
          <el-form :label-position="labelPosition" label-width="8.125rem" :model="passwordForm">
            <el-form-item label="当前密码">
              <el-input type="password" v-model="passwordForm.oldPwd"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input type="password" v-model="passwordForm.newPwd"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input type="password" v-model="passwordForm.confirmPwd"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="apply" @click="clickSave">保存</div>
      </el-tab-pane>
      <el-tab-pane label="绑定账号" name="second">
        <div class="bind-user">
          <div class="title-div">
            <div class="title">手机号码</div>

          </div>
          <div class="phone">{{userPhone}}</div>
          <div class="edit" @click="editPhone">修改</div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 类型下拉选择其他弹框 -->
    <my-dialog
        title="手机号码验证"
        :visible.sync="showFlag"
        @before-close="refreshLX"
        width="22.6875rem"
    >
      <el-form
          :model="formData"
          ref="LXForm"
          size="small"
          :inline="true"
          class="my-form"
          :label-position="'top'"
      >
        <el-row style="text-align: left">
          <el-col class="flex_center" :span="24">
            <el-form-item label="手机号码">
              <el-input
                  v-model="formData.phone"
                  placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="text-align: left;display: flex;align-items: flex-end;justify-content: space-between">
          <el-col class="" :span="16" style="margin-right: 1rem;">
            <el-form-item label="验证码">
              <el-input
                  v-model="formData.code"
                  placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="flex_center" :span="8">
            <el-form-item label="">
              <div class="flex-center-a" @click="getCode()">获取验证码
              </div>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <el-row>
        <el-col class="flex_end_j">
          <div class="flex_end_j_a" @click="showFlag = false">取消
          </div>
          <div class="flex_end_j_b" @click="jumperPhone">确定
          </div>
          <!--            <el-button-->
          <!--                type="primary"-->
          <!--                round-->
          <!--                icon="cus-icon-save"-->
          <!--                @click="aa"-->
          <!--            >-->
          <!--              保 存-->
          <!--            </el-button>-->
        </el-col>
      </el-row>
    </my-dialog>
  </div>
</template>

<script>
import {httpGet,httpPost,httpNPGet} from "@/utils/httpRequest";
export default {
  name: "SafeCenter",
  data() {
    return {
      activeName: "first",
      labelPosition: "top",
      formLabelAlign: {},
      showFlag: false,
      formData: {
        phone: "",
        code: "",
      },
      passwordForm: {
        phone:"",
        userId:"",
        oldPwd:"",
        newPwd:"",
        confirmPwd:"",
      },
      userPhone:"",
    }
  },
  methods: {
    refreshLX() {
      this.showFlag = false;
      this.formData.LXName = "";
    },
    editPhone() {
      this.showFlag = true;
    },
    // 获取验证码
    getCode(){
      httpPost("/captcha/send",
      {
        phone: this.formData.phone
      },
      (res)=>{
        // console.log(res)
      },
      (err)=>{
        // console.log(err);
        throw err
      })
    },

    // 点击保存
    clickSave() {
      this.passwordForm.userId = localStorage.getItem('userId'),
      
      httpPost("/app/app-user/update",
        this.passwordForm,
        (res)=>{
          // console.log(this.passwordForm)
          this.$message.success("修改成功")
        },
        (err)=>{
          if(err.code==500){
            if(err.msg=="token异常，请重新登录"){
              localStorage.clear();
              if(!localStorage.getItem("userId")){
                this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login")
              }else{
              }
            }
          }
          this.$message.error(err.msg)
          throw err 
        }
      )
    },
    // tabs切换
    handleClick() {

    },
    // 更换现有手机号
    jumperPhone(){
      httpGet("/captcha/contrast",
        this.formData,
        (res)=>{
          // console.log(res)
          this.$router.push({ name: 'BindPhone'});
        },
        (err)=>{
          // console.log(err);
          throw err 
        }
      )
    },
    // 获取手机号
    getUserMsg(){
      httpPost("/app/app-user-identity/detail ",
      {
        userId: localStorage.getItem('userId'),
      },
      (res)=>{
        // console.log(res)
        this.userPhone = res.data.phone
      },
      (err)=>{
        if(err.code==500){
          if(err.msg=="token异常，请重新登录"){
            localStorage.clear();
            if(!localStorage.getItem("userId")){
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
        }
        // console.log(err);
        throw err
      })
    },
  },
  mounted(){
    this.getUserMsg()
  }
}
</script>

<style lang="scss" scoped>
.safe-center {
  padding: 0 3.38624%;
  background: #FFFFFF;
   border-radius: 10px;
  height: 49.5rem;

  .my-form {
    padding: 0 2.625rem;
  }

  .flex_end_j {
    display: flex;
    justify-content: flex-end;
    padding-right: 4.40771%;
    height: 3.25rem;
    border-top: 0.0625rem solid #EDEFF2;
    align-items: center;
    .flex_end_j_a{
    width: 15.97796%;
    height: 2rem;
    background: #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    border: 0.0625rem solid #E6E8EB;font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 2rem;
    margin-right: 2.75482%;
    cursor: pointer;
  }
  .flex_end_j_b{
    width: 3.625rem;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0px 0.25rem 0.375rem 0px rgba(19,113,231,0.08);
    border-radius: 10px;font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    cursor: pointer;
  }
  }

  ::v-deep .el-dialog__header {
    text-align: left;
    height: 1.5rem;
    font-size: 1.0625rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5rem;
    padding: 1rem;
    border-bottom: 0.0625rem solid #EDEFF2;;
  }

  .bind-user {
    height: 5.8125rem;
    background: #EDEFF2;
    border-radius: 10px;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    line-height: 6.25rem;

    .title-div {
      display: inline-block;

      .title {
        display: flex;
        align-items: center;
        height: 1.75rem;
        font-size: 1.25rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #3E454D;
        line-height: 1.75rem;
      }

      .title::before {
        content: "";
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background: url('~@/assets/img/pre/icon-bind-phone.png');
        background-size: 100% 100%;
        margin-right: 0.625rem;
      }
    }


    .phone {
      display: inline-block;
      height: 2.8125rem;
      font-size: 2rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1371E7;
      line-height: 2.375rem;
      margin-left: 2.26757%;
    }

    .edit {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      right: 2.72108%;
      top: 1.75rem;
      width: 3.625rem;
      height: 1.5rem;
      background: #1371E7;
      box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
      border-radius: 10px;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.5rem;
      text-align: center;
    }
  }

  .bill-form {
    margin-top: 1.25rem;
    width: 29.5983%;
    text-align: left;
    font-size: 0.875rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
  }

  .apply {
    width: fit-content;
    padding: 0 1.8161%;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    text-align: center;
    //margin-top: 20px;
  }
  .flex-center-a{
    width: 5.75rem;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0px 0.25rem 0.375rem 0px rgba(19,113,231,0.08);
    border-radius: 10px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
  }
  ::v-deep .el-tabs {
    background-color: #FFFFFF;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #1371E7;
    height: 4px;
  }

  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #000000;
  }

  ::v-deep .el-tabs__item {
    height: 64px;
    line-height: 64px;
    font-size: 15px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 40px;
    color: #667280;
  }

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  ::v-deep .el-tabs__content {
    height: 641px;
    overflow: hidden;
    overflow-y: scroll;
    text-align: left;
  }

  ::v-deep .el-form-item {
    margin-bottom: 24px;
    width: 100%;
  }

  ::v-deep .el-form-item__label {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    padding: 0;
  }

  ::v-deep .el-input__inner {
    line-height: 32px;
    height: 32px;
  }
}
</style>